import {ref} from 'vue';

export const useCustomDialog = () => {
  const isCustomDialogDisplay = ref(false);

  const closeDialog = () => {
    isCustomDialogDisplay.value = false;
  }

  return {
    isCustomDialogDisplay,
    closeDialog,
  }
}
