
import {defineComponent} from 'vue';
import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';

export default defineComponent({
	name: 'custom-dialog',

	component: { ButtonCustom },

	emits: ['on-close-icon-click'],

	props: {
		title: {
			type: String,
			required: true,
		},

		isDisplay: {
			type: Boolean,
			required: true,
		},

		width: {
			type: String,
			default: '50%',
		}
	},

	data() {
		return {
			isDialogDisplay: false,
		}
	},

	watch: {
		isDisplay: {
			handler() {
				this.isDialogDisplay = this.isDisplay;
			},
			deep: true,
		}
	},

	methods: {
		onCloseIconClickedHandler() {
			this.$emit('on-close-icon-click');
		}
	}
})
