import {ref} from 'vue';
import { ElLoading } from 'element-plus'

export const useLoading = () => {
  const isLoading = ref(false);
  let loading;

  const setLoading = (value) => {
    isLoading.value = value;
  }

  const startLoading = async (text = 'Đang xử lý') => {
    setLoading(true);

    loading = ElLoading.service({
      background: 'rgba(0, 0, 0, 0.7)',
      lock: true,
      text,
    })
  }

  const endLoading = async () => {
    setLoading(false);

    if (loading) {
      loading.close();
    }
  }

  return {
    isLoading,
    startLoading,
    endLoading,
    setLoading,
  }
}
