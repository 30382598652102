import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isDialogDisplay,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isDialogDisplay) = $event)),
    width: _ctx.width,
    onClose: _ctx.onCloseIconClickedHandler
  }, {
    title: _withCtx(() => [
      _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.title), 1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "dialog-content")
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "dialog-footer")
      ])
    ]),
    _: 3
  }, 8, ["modelValue", "width", "onClose"]))
}