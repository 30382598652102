
import * as Yup from "yup";
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { currentSelectableYear } from "@/core/constants/const";
import { getYearFromLocalStorage } from '@/core/helpers/utils';
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {useCustomDialog} from '@/core/hooks/use-custom-dialog';
import {useLoading} from '@/core/hooks/use-loading';
import { useButtonCustom } from '@/core/hooks/use-button-custom';

import ButtonCustom from '@/components/shared/buttons/ButtonCustom.vue';
import CustomDialog from '@/components/custom-dialog/CustomDialog.vue';

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
		ButtonCustom,
		CustomDialog,
  },
  setup() {
		const { isCustomDialogDisplay, closeDialog } = useCustomDialog();
		const { startLoading, endLoading} = useLoading();
    const { ButtonsType, ButtonTypeColors, ButtonTypes } = useButtonCustom();

    const store = useStore();
    const router = useRouter();

    const curYear = getYearFromLocalStorage();

    const selectedYear = ref<number>(new Date().getFullYear());

    if (curYear) {
      selectedYear.value = +curYear;
    }

		const phone = ref();
		const password = ref();

    //Create form validation object
    const login = Yup.object().shape({
      phone: Yup.string()
        .required("Bắt buộc phải nhập")
        .label("Số điện thoại"),
      password: Yup.string().min(4).required().label("Mật khẩu"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
			try {
				await store.dispatch(Actions.LOGOUT);
			} catch {
				// TODO
			}

			startLoading();

      await store.dispatch(Actions.LOGIN, {
				phone: phone.value,
				password: password.value,
			});

			endLoading();

      const error = store.getters.getErrors;

      if (!error) {
        await store.commit(Mutations.SET_YEAR, selectedYear.value.toString());
				await router.push({ name: "dashboard" });
      } else {
        isCustomDialogDisplay.value = true;
      }
    };

    return {
      ButtonsType, ButtonTypeColors, ButtonTypes,
      onSubmitLogin,
      login,
      currentSelectableYear,
      selectedYear,
			isCustomDialogDisplay,
			closeDialog,
			phone,
			password,
    };
  },
});
